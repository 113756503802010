import axios, { setBaseUrl, baseURL, getRegistryUrl } from './axios'

export function setBaseURL (url) {
  setBaseUrl(url)
  if (url !== '') getBaseUrlReady.resolve(true)
}

class Completer {
  constructor () {
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
    })
  }
}

export const getBaseUrlReady = new Completer()

export function getBaseURL (uuid, code, verifyCode, baseurl, region) {
  if (baseurl) return Promise.resolve(baseurl)
  return axios.get(`${getRegistryUrl(region)}/syy/frp/share/binding?uuid=${uuid}&shareCode=${code}`).then((resp) => {
    if (resp.code === 'A00200' && resp.data.length > 0) {
      // 只使用数据传输节点，过滤掉元数据节点
      var list = resp.data.filter((item) => item.type === 1)
      if (list.length > 0) {
        return `https://${list[0].address}/${uuid}`
      } else {
        throw resp
      }
    } else {
      throw resp
    }
  })
  // return Promise.resolve('https://20022.pntc.oriconas.com/d446ae2595cd022a1f08b25e51a67275')
}

export function getShareStatus (shareCode) {
  return axios.get(`/syy/v2/fs/share/code/info?shareCode=${shareCode}`)
}

export function cdDir (shareCode, verifyCode, pufi) {
  return axios.get(`/syy/v2/fs/share/object/list?page=1&pageSize=1000&shareCode=${shareCode}&verifyCode=${verifyCode}&pufi=${encodeURIComponent(pufi)}`)
}

export function analyticFolder (shareCode, verifyCode, pufi) {
  return axios.get(`/syy/v2/fs/share/dir/size?shareCode=${shareCode}&verifyCode=${verifyCode}&pufi=${encodeURIComponent(pufi)}`)
}

export function getDownloadUrl (shareCode, verifyCode, uuid) {
  return `${baseURL}/syy/v2/fs/share/objects?shareCode=${shareCode}&verifyCode=${verifyCode}&token=${uuid}&download=true`
}

export function getSingleFileDownloadUrl (shareCode, verifyCode, ufi) {
  return `${baseURL}/syy/v2/fs/share/object?shareCode=${shareCode}&verifyCode=${verifyCode}&ufi=${encodeURIComponent(ufi)}&download=true`
}

export function getSingleFileThumbnailUrl (shareCode, verifyCode, ufi) {
  return `${baseURL}/syy/v2/fs/share/object?shareCode=${shareCode}&verifyCode=${verifyCode}&ufi=${encodeURIComponent(ufi)}&thumbnailType=pixel256`
}

export function getSingleFilePreviewUrl (shareCode, verifyCode, ufi) {
  return `${baseURL}/syy/v2/fs/share/object?shareCode=${shareCode}&verifyCode=${verifyCode}&ufi=${encodeURIComponent(ufi)}&avatar=true`
}

export function getDownloadUuid (shareCode, verifyCode, ufis) {
  return axios.post(`/syy/v2/fs/share/objects/request?shareCode=${shareCode}&verifyCode=${verifyCode}`, { ufis: ufis })
}

export function getDeviceInfo () {
  return axios.get('/syy/siyouyun/device/info')
}
