import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import { getShareStatus, cdDir, getDownloadUuid, getDownloadUrl, analyticFolder, getDeviceInfo, getBaseUrlReady, getBaseURL, setBaseURL, getSingleFilePreviewUrl, getSingleFileThumbnailUrl, getSingleFileDownloadUrl } from './api/index';
import querystring from 'query-string';
import { Toast } from 'vant';
import 'vant/es/toast/style';
import { filesize } from 'filesize';
import dayjs from 'dayjs';
import { baseURL, setEnv } from './api/axios';
function parseUFI(ufiPath) {
  const ufiPattern = /^\/ufi\/([^/]+)\/([^/]+)(\/.*)?$/;
  const match = ufiPath.match(ufiPattern);
  if (!match) {
    return null; // 路径格式不正确，返回null
  }

  // eslint-disable-next-line no-unused-vars
  const [_, storageType, storageUuid, fullPath = ''] = match;
  return {
    ufiPrefix: `/ufi/${storageType}/${storageUuid}`,
    fullPath,
    storageType,
    storageUuid
  };
}
function setFavicon(url) {
  let link = document.querySelector("link[rel='icon']");
  if (!link) {
    // 如果没有找到现有的 <link> 标签，就创建一个新的
    link = document.createElement('link');
    link.rel = 'icon';
    document.head.appendChild(link);
  }

  // 设置新的 favicon URL
  link.href = url;
}
export default {
  name: 'App',
  data() {
    return {
      baseURL: baseURL,
      loadingNodeInfo: true,
      loadingNodeInfoFailed: false,
      loadingNodeInfoMsg: '',
      loading: false,
      outdate: false,
      invalidUrl: false,
      needVerifyCode: true,
      dir: '/',
      cdnMode: true,
      uuid: '',
      shareCode: '',
      verifyCode: '',
      region: '',
      brandName: '',
      brandNameEn: '',
      homepage: '',
      totalSize: 0,
      filelist: [],
      checked: [],
      shareInfo: {}
    };
  },
  mounted() {
    this.initPage();
  },
  computed: {
    isBigScreen() {
      return window.innerWidth > 500;
    },
    dirs() {
      var list = [{
        name: '/根目录',
        path: '/'
      }];
      if (!this.dir || this.dir === '/') {
        return list;
      }
      const ufiObj = parseUFI(this.dir);
      console.log('ufiObj', ufiObj);
      const dirarr = ufiObj.fullPath.split('/');
      return [...list, ...dirarr.map((item, index) => {
        if (index === 0) {
          return {
            name: `/${ufiObj.storageType}:${ufiObj.storageUuid}`,
            path: `/ufi/${ufiObj.storageType}/${ufiObj.storageUuid}`
          };
        }
        return {
          name: '/' + item,
          path: ufiObj.ufiPrefix + dirarr.slice(0, index + 1).join('/')
        };
      })];
    }
  },
  watch: {
    dirs() {
      this.checked = [];
      console.log('dirs', this.dirs);
    },
    $route() {
      this.dir = this.$route.query.dir || '/';
      getBaseUrlReady.promise.then(() => {
        this.getFileList();
      });
    },
    filelist() {
      this.filelist.forEach(file => {
        this.ctotal();
        if (file.fileInfo.isDir) {
          analyticFolder(this.shareCode, this.verifyCode, file.ufi).then(resp => {
            if (resp.code === 'A00200') {
              file.fileInfo.size = resp.data;
              this.ctotal();
            }
          });
        }
      });
    }
  },
  methods: {
    initPage() {
      var paths = document.location.pathname.split('/') || [];
      // 有pathuuid的是NAS直连的情况，否则是通过CDN访问
      var pathuuid = !document.location.href.startsWith('https://share.') && paths.length >= 2 ? paths[1] : '';
      this.cdnMode = !pathuuid;
      var baseurl = '';
      if (pathuuid) {
        baseurl = `${document.location.protocol}//${document.location.host}/${pathuuid}`;
      }
      const se = querystring.parse(window.location.search);
      this.uuid = se.uuid;
      this.shareCode = se.code;
      this.verifyCode = se.verifycode;
      var region = typeof se.region === 'string' ? se.region.toUpperCase() : 'OLD';
      this.region = ['OLD', 'CN', 'SEA', 'EU', 'US-WEST', 'US-EAST'].includes(region) ? region : 'OLD';
      if (this.cdnMode && !this.region) {
        this.loadingNodeInfo = false;
        this.loadingNodeInfoFailed = true;
        this.loadingNodeInfoMsg = '分享链接缺少服务区信息，请联系分享者升级系统至v2.0.10版本以上、APP至v2.1.5版本以上，并重新复制分享链接';
        return;
      }
      this.loadingNodeInfo = true;
      const isProd = se.env !== 'test';
      setEnv(isProd);
      getBaseURL(this.uuid, this.shareCode, this.verifyCode, baseurl, this.region).then(resp => {
        setBaseURL(resp);
        this.baseURL = resp;
        setFavicon(`${resp}/syy/assets/mainboard/favicon`);
        this.loadingNodeInfo = false;
        this.dir = this.$route.query.dir || '/';
        this.getStatus();
        getDeviceInfo().then(resp => {
          if (resp.code === 'A00200') {
            this.brandName = resp.data.mainBoard.brandName;
            this.brandNameEn = resp.data.mainBoard.brandNameEn;
            this.homepage = resp.data.mainBoard.homepage;
            window.document.title = `${this.brandName}文件分享`;
          }
        });
      }).catch(err => {
        if (err.code === 'A00500') {
          this.loadingNodeInfo = false;
          this.loadingNodeInfoFailed = true;
          this.loadingNodeInfoMsg = this.region === 'OLD' ? '无法连接到分享设备，可能使用了过时的分享链接，请联系分享者升级系统至v2.0.10版本以上、APP至v2.1.5版本以上，并重新复制分享链接' : '无法连接到分享设备，可能设备关机、休眠或服务区发生变更，请联系分享者';
        } else {
          this.loadingNodeInfo = false;
          this.loadingNodeInfoFailed = true;
          this.loadingNodeInfoMsg = '获取公网穿透设置错误，无法连接到Registry服务';
        }
      });
    },
    getSingleFileThumbnailUrl() {
      return getSingleFileThumbnailUrl(...arguments);
    },
    getSafeTextOverflow(str, start = 5, end = 5) {
      if (str.length <= start + end + 3) {
        return str;
      }
      return str.substring(0, start) + '...' + str.substring(str.length - end);
    },
    filesize(size = 0) {
      return filesize(size, {
        base: 2,
        standard: 'jedec'
      });
    },
    ctotal() {
      this.totalSize = this.filelist.reduce((total, current, index, list) => {
        total = total + current.fileInfo.size;
        return total;
      }, 0);
    },
    dtformat(ms) {
      return dayjs(ms).format('YYYY-MM-DD HH:mm:ss');
    },
    async getStatus() {
      if (!this.shareCode) {
        return this.invalidUrl = true;
      }
      try {
        this.loading = true;
        var res = await getShareStatus(this.shareCode);
        this.loading = false;
        if (res.code === 'CST0104') {
          return this.outdate = true;
        }
        if (res.code === 'CST0105') {
          this.outdate = false;
          this.needVerifyCode = true;
          return;
        }
        if (res.code === 'A00200') {
          this.shareInfo = res.data;
          this.outdate = false;
          this.needVerifyCode = false;
          this.getFileList();
          return;
        }
        throw new Error(res.msg);
      } catch (error) {
        this.loading = false;
        this.invalidUrl = true;
      }
    },
    async verify() {
      if (!this.verifyCode) {
        return Toast('请输入提取码');
      } else {
        try {
          var res = await cdDir(this.shareCode, this.verifyCode, this.dir);
          this.loading = false;
          if (res.code === 'A00200') {
            var _res$data;
            this.filelist = ((_res$data = res.data) === null || _res$data === void 0 ? void 0 : _res$data.data) || [];
            this.needVerifyCode = false;
          } else if (res.code === 'CST0106') {
            this.needVerifyCode = true;
            Toast('提取码错误');
          } else {
            Toast(res.msg || '未知错误');
          }
        } catch (error) {
          this.loading = false;
          this.needVerifyCode = true;
        }
      }
    },
    clickpush(p) {
      this.$router.push({
        path: '/',
        query: {
          dir: p
        }
      });
    },
    clickItem(item) {
      if (item.isDir) {
        this.$router.push({
          path: '/',
          query: {
            dir: item.ufi
          }
        });
      } else {
        window.open(getSingleFilePreviewUrl(this.shareCode, this.verifyCode, item.ufi));
      }
    },
    async getFileList() {
      try {
        this.loading = true;
        var res = await cdDir(this.shareCode, this.verifyCode, this.dir);
        if (res.code === 'A00200') {
          var _res$data2;
          this.filelist = res.data instanceof Array ? res.data : ((_res$data2 = res.data) === null || _res$data2 === void 0 ? void 0 : _res$data2.data) || [];
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.invalidUrl = true;
      }
    },
    toggleSelected() {
      if (this.checked.length > 0) {
        this.checked = [];
      } else {
        this.checked = this.filelist.map(file => file.ufi);
      }
    },
    async downloadAll() {
      var ufis = this.filelist.map(item => item.ufi);
      try {
        var res = await getDownloadUuid(this.shareCode, this.verifyCode, ufis);
        console.log('getDownloadUuid', res);
        if (!res.data) {
          return Toast('uuid请求失败');
        }
        var url = getDownloadUrl(this.shareCode, this.verifyCode, res.data);
        this.down(url, '');
      } catch (error) {
        Toast(error.message);
      }
    },
    async download() {
      var isSingleDir = false;
      if (this.checked.length === 1) {
        var fileItem = this.filelist.find(file => file.ufi === this.checked[0]);
        isSingleDir = (fileItem === null || fileItem === void 0 ? void 0 : fileItem.fileInfo.isDir) || false;
      }
      if (this.checked.length === 0) {
        return Toast('请先选择文件');
      } else if (this.checked.length === 1 && !isSingleDir) {
        try {
          const url = await getSingleFileDownloadUrl(this.shareCode, this.verifyCode, this.checked[0]);
          console.log('getSingleFileDownloadUrl', url);
          this.down(url, this.checked.length === 1 ? this.checked[0] : '');
        } catch (error) {
          Toast(error.message);
        }
      } else {
        try {
          const res = await getDownloadUuid(this.shareCode, this.verifyCode, this.checked);
          console.log('getDownloadUuid', res);
          if (!res.data) {
            return Toast('uuid请求失败');
          }
          const url = getDownloadUrl(this.shareCode, this.verifyCode, res.data);
          this.down(url, this.checked.length === 1 ? this.checked[0] : '');
        } catch (error) {
          Toast(error.message);
        }
      }
    },
    down(url, name) {
      const aDom = document.createElement('a');
      aDom.style.display = 'none';
      aDom.href = url;
      console.log('down', url, name);
      if (name) {
        aDom.download = name;
        // aDom.setAttribute('download', name)
      }
      document.body.appendChild(aDom);
      aDom.click();
      document.body.removeChild(aDom);
    }
  }
};